export const environment = {
  production: true,
  message: 'Building for Movinglayers PRD environment',
  SERVER_URL: 'https://app.abm4energytransition.eu',
  SERVER_PORT: '443',
  BACKEND_URL: 'https://app-be.abm4energytransition.eu',
  BACKEND_PORT: '443',
  OTA_VERSION_URL: '',
  BUILD_VERSION: 'BUILD_VERSION_CI' //Replaced by CI to something like v0.0.1
}
